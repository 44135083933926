import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useConfigCatClient, User} from "configcat-react";
import {AuthContext} from "../components/AuthProvider";
import _ from "lodash";
import {Constants} from "../components/common/Constants";

type FeatureFlagProviderProps = {
  children: JSX.Element[] | JSX.Element;
};

type FeatureFlagState = {
  featureFlagsInitialized: boolean;
  manifestOptimization: boolean;
  driverRequirements: boolean;
  driverMessaging: boolean;
  colorSort: boolean;
  dispatcherIgnoreTimeWindows: boolean;
  datadogEnabled: boolean;
  datadogSessionSampleRate: number;
  datadogSessionReplaySampleRate: number;
  oktaDevMode: boolean;
  oktaMaxClockSkew: number;
  manifestPageSizes: number[];
  markStopComplete: boolean;
  manifestDetailsConfiguration: boolean;
  extraManifestFilters: boolean;
  optimizeUsingShipments: boolean;
};

const initialState: FeatureFlagState = {
  featureFlagsInitialized: false,
  manifestOptimization: false,
  driverRequirements: false,
  driverMessaging: false,
  colorSort: false,
  dispatcherIgnoreTimeWindows: false,
  datadogEnabled: true,
  datadogSessionSampleRate: 100,
  datadogSessionReplaySampleRate: 100,
  oktaDevMode: false,
  oktaMaxClockSkew: Constants.DEFAULT_MAX_CLOCK_SKEW_SECONDS,
  manifestPageSizes: Constants.MANIFEST_PAGE_SIZE_OPTIONS,
  markStopComplete: false,
  manifestDetailsConfiguration: false,
  extraManifestFilters: false,
  optimizeUsingShipments: false
};

const CSVNumberFlagKeys: (keyof FeatureFlagState)[] = ["manifestPageSizes"];

const FeatureFlagContext = createContext<FeatureFlagState>(initialState);

const FeatureFlagProvider = ({children}: FeatureFlagProviderProps) => {
  const client = useConfigCatClient();
  const [state, setState] = useState<FeatureFlagState>({
    ...initialState
  });

  const {user, tenant, tenantId} = useContext(AuthContext);
  const configCatUser = useMemo(() => {
    if (user && tenant && tenantId) {
      return {
        identifier: user.name,
        email: user.email,
        custom: {
          tenantId: tenantId,
          tenantName: tenant.shortName
        }
      } as User;
    }
  }, [user, tenant, tenantId]);

  const validKeys = Object.keys(initialState);

  useEffect(() => {
    if (configCatUser) {
      client.getAllValuesAsync(configCatUser).then((settings) => {
        const typedState: FeatureFlagState = {} as FeatureFlagState;
        const anyState: any = {};
        const invalidKeys: string[] = [];

        settings.forEach((setting) => {
          if (setting.settingValue != null) {
            if (!validKeys.includes(setting.settingKey)) {
              invalidKeys.push(setting.settingKey);
            } else if (CSVNumberFlagKeys.includes(setting.settingKey as any)) {
              anyState[setting.settingKey] = setting.settingValue.toString().split(",").map(Number);
            } else {
              anyState[setting.settingKey] = setting.settingValue;
            }
          }
        });
        if (invalidKeys.length > 0) {
          console.warn("Unmapped feature flags: %s", invalidKeys);
        }
        const newState = {...state, ...anyState, ...typedState} as FeatureFlagState;
        newState.featureFlagsInitialized = true;

        if (!_.isEqual(newState, state)) {
          setState(newState);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, configCatUser, validKeys]);

  return <FeatureFlagContext.Provider value={state}>{children}</FeatureFlagContext.Provider>;
};

export default FeatureFlagProvider;
export {FeatureFlagContext};
export type {FeatureFlagState};
